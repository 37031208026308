export function convertJob(slug) {
    return this.post(`/api/v2/jobs/${slug}/convert`)
}

export function getJobBySlug(slug) {
    return this.get(`/api/v2/jobs/${slug}`)
}

export function getJobs(businessSlug) {
    return this.get("/api/v2/jobs", { business: businessSlug })
}

export function getTotalJobStats(slug, start_date = null, end_date = null) {
    return this.get(`/api/statistics/jobs/${slug}`, {
        start_date,
        end_date,
    })
}

export function getInstitutions(apprenticeshipTypeId) {
    return this.get(`/api/institutions`, {
        apprenticeship_type_id: apprenticeshipTypeId,
    })
}
